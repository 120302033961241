<template>
  <div class="contenido">
    <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span>
                <font-awesome-icon class="icono-modal white" :icon="['fal', 'lightbulb-on']" />
                <span class="title-m bold white">{{
                  $t("COMPETITION.STIMULUS_LOWER")
                }}</span>
                <span v-if="estimulosCreativos" class="date-stimulus title-m white">
                  {{ estimulosCreativos[indexSelectedEstimulo].fecha_inicio.split('T')[0] }}
                </span>
                <font-awesome-icon v-if="estimulosCreativos && estimulosCreativos.length !== 1" class="icono-modal white"
                  style="cursor: pointer;" :icon="['far', 'chevron-left']"
                  @click="indexSelectedEstimulo = (indexSelectedEstimulo - 1) < 0 ? estimulosCreativos.length - 1 : indexSelectedEstimulo - 1" />
                <font-awesome-icon v-if="estimulosCreativos && estimulosCreativos.length !== 1" class="icono-modal white"
                  style="cursor: pointer;" :icon="['far', 'chevron-right']"
                  @click="indexSelectedEstimulo = (indexSelectedEstimulo + 1) > estimulosCreativos.length - 1 ? 0 : indexSelectedEstimulo + 1" />
              </span>
            </div>
            <div>
              <button class="btn-modal btn button" @click="isModalActive = false">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'times']" />
                  <span class="text-l white">{{
                    $t("COMPETITION.CLOSE_STIMULUS")
                  }}</span>
                </span>
              </button>
            </div>
          </div>
          <div class="stimulus text-l gray">
            <div class="estimulo" v-if="estimulosCreativos">
              <span class="title-stimulus">{{ estimulosCreativos[indexSelectedEstimulo].titulo }}</span>
              <p>
                <img :src="'data:image;base64,' + estimulosCreativos[indexSelectedEstimulo].file_data"
                  :title="estimulosCreativos[indexSelectedEstimulo].orig_filename" alt="" />
              </p>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <ImageHeader :title="$t('EVALUATOR.TITILE')" />
    <div class="is-10 container-data container">
      <div class="container is-10 data">
        <div class="columns personal-data">
          <div class="is-5 is-offset-1 column has-text-left">
            <p class="title-m black bold">
              {{ user.nombre }} {{ user.primer_apellido }}
              {{ user.segundo_apellido }}
            </p>
            <br />
            <div class="text-m info-user">
              <span class="info-lit">{{ $t("MANAGER.PHASE", { faseNum: ($store.state.fases.fasesActuales[0].codigo === 'evaluacion_2' ? 2 : 1) }) }}</span>
              <span class="info-lit"><span class="icon-with-lit">
                  <font-awesome-icon :icon="['fal', 'globe']" /> {{ userEval.nombre }}
                </span></span>
            </div>
          </div>
        </div>
        <div class="columns selectors">
          <div class="is-2 is-offset-1 column has-text-left">
            <button class="btn button" @click="isModalActive = true;">
              <span>
                <font-awesome-icon class="icono" :icon="['fal', 'lightbulb-on']" />
                <span class="text-m black">{{
                  $t("COMPETITION.STIMULUS")
                }}</span>
              </span>
            </button>
          </div>
        </div>
        <div class="columns" style="margin-bottom: 20px; margin-top: 5px;">
          <div class="column is-10 is-offset-1">
            <span class="description">{{$t('PROVINCE_SELECTOR.DESCRIPTION')}}</span>
          </div>
        </div>
        <div class="columns">
          <div class="column is-10 is-offset-1">
            <div class="columns" style="margin-bottom: 15px;">
              <div class="column is-3" v-for="provincia in provincias" :key="provincia.provincia" @click="selectProvince(provincia.nombre)">
                <div class="card">
                  <div class="card-content">
                    <div class="content has-text-centered is-vcentered">
                      <h4 class="name">
                        {{ provincia.nombre }}
                      </h4>

                      <div class="icono-calificacion" :class="{ 'state-pending': !provincia.finalizado, 'state-done': provincia.finalizado }">
                        <font-awesome-icon class="icon" :icon="['fas', (provincia.finalizado ? 'check' : 'minus')]" />
                      </div>

                      <p :class="{ 'state-pending': !provincia.finalizado, 'state-done': provincia.finalizado }">
                        {{ provincia.finalizado ? $t('PROVINCE_SELECTOR.QUALIFIED') : $t('PROVINCE_SELECTOR.PENDING') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader.vue";
import { FASES } from "../constants.js";

export default {
  name: "seleccion-provincia",
  components: {
    ImageHeader
  },
  data() {
    return {
      user: {},
      userEval: {},
      provincias: [],
      isModalActive: false,
      estimulosCreativos: undefined,
      indexSelectedEstimulo: 0
    };
  },
  created() {
    this.$store.commit("setShowSpinner", true);
    this.$store
      .dispatch("getUser")
      .then((user) => {
        this.user = user;
        this.$store
          .dispatch("getEstimulosByIdioma", {
            idioma: this.$i18n.locale,
          })
          .then((estimulos) => {
            this.estimulosCreativos = estimulos;
          });
        this.$store
          .dispatch("getUsuarioEvaluacion", {
            idioma: this.$i18n.locale,
            fase: this.$store.state.fases.fasesActuales[0].codigo,
          })
          .then((usuario_evaluacion) => {
            this.userEval = usuario_evaluacion[0];
            if (usuario_evaluacion.length > 0) {
              usuario_evaluacion.map(user_eval => {
                this.provincias.push(user_eval);
              });

              this.$store.commit("setUsuarioEvaluaciones", usuario_evaluacion);
              this.$store.commit("setShowSpinner", false);
            }
          });
      })
      .catch(() => {
        this.$store.commit("setShowSpinner", false);
      });
  },
  methods: {
    selectProvince(province) {
      this.$store.commit("setCurrentProvince", province);
      
      if (this.$store.state.fases.fasesActuales[0].codigo === 'evaluacion_2'){
        this.$router.push({
          name: "evaluadorf2",
          params: { provincia: province },
        });
      } else {
        this.$router.push({
          name: "evaluador",
          params: { provincia: province },
        });
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.personal-data {
  padding-top: 25px;
}

.selectors {
  background-color: $primary;
  margin-left: 0px;
  margin-right: 0px;
}

.container-data {
  margin-top: -150px;
  padding-bottom: 100px;
}

.container.data {
  background-color: white;
}

.btn {
  background-color: white;
  height: 50px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  .content-button {
    display: flex;
    align-items: center;
  }

  .icono {
    font-size: 20px;
    color: $primary;
    margin-right: 10px;
  }
}

.info-user {
  .info-lit {
    padding-right: 22px;
  }

  display: flex;
  align-items: center;

  .selector-zona {
    display: flex;
    align-items: center;
  }
}

.icono-modal {
  font-size: 25px;
  margin-right: 20px;
}

.btn-modal {
  background-color: $primary;

  .icono {
    color: white;
  }
}

.stimulus {
  padding: 40px;
  background-color: white;
  margin-top: 25px;
  border-radius: 10px;
}

.parte-superior {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-stimulus {
  font-weight: bold;
  font-size: 22px;
}

.date-stimulus {
  margin-left: 15px;
  margin-right: 15px;
}

.description {
  font-size: 1.2em;
  font-weight: bold;
}

.card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;

  .card-content {
    width: 100%;
  }

  p {
    font-size: 1.4em;
    font-weight: bold;
    text-align: center;
  }

  .name {
    color: $primary;
  }

  .state-pending {
    color: #FF7F00;
  }

  .state-done {
    color: #66B963;
  }

  h4 {
    &:after {
      display:block;
      padding-bottom: 15px;
      content: '';
      border-bottom: solid 1px #4A4A4A;  
      transform: scaleX(80%);  
      transition: transform 250ms ease-in-out;
    }
  }
}

.card:hover {
  h4:after { transform: scaleX(1); }

  .icono-calificacion {
    transform: rotateY(180deg);
  }

  .icon {
    transform: rotateY(-180deg);
  }
}

.icono-calificacion{
  background-color: white;
  width: 45px;
  height: 45px;
  display: inline-grid;
  margin: 5px;
  border-radius: 50%;
  font-size: 22px;
  border: 3px solid;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  transition: transform 0.5s ease;

  .icon {
    transition: transform 0.5s ease;
  }
}

</style>